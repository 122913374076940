
import { ref } from 'vue';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import { hideModal } from '@/core/helpers/dom';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default {
  name: 'ManageType',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Car & Function', ['Manage Car Type']];
    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'Type EN',
        key: 'name',
        sortable: true,
      },
      {
        name: 'Type TH',
        key: 'name',
        sortable: true,
      },
      {
        name: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];

    const currentIndex = ref(-1);
    const inputRef = ref({
      name: '',
    });
    const modalRef = ref(null);
    const tableRef = ref<any>(null);

    const create = async () => {
      await ApiService.post('car-type', {
        name: inputRef.value.name,
      });

      hideModal(modalRef.value);

      Swal.fire({
        text: 'New Type has been created',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Return to Type Lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      inputRef.value = {
        name: '',
      };

      await tableRef.value.getData(
        tableRef.value.currentPage,
        tableRef.value.rowsPerPage
      );

      reinitializeComponents();
    };

    return {
      tableHeader,
      breadcrumbs,
      currentIndex,
      inputRef,
      modalRef,
      create,
      tableRef,
    };
  },
};
